<template>
  <div v-if="vto_bno && vto_bno.vo_bo_user_id" class="col-md-4">
    <div class="small-box bg-teal m-0">
      <div class="inner">
        <h3>Visto Bueno</h3>
        <p class="mb-1">
          <strong>{{ vto_bno.vo_bo_user.name }}</strong>
        </p>
        <p class="text-sm mb-1">{{ vto_bno.observacion }}</p>
      </div>
      <div class="icon">
        <i class="fas fa-check"></i>
      </div>
      <a class="small-box-footer">{{ vto_bno.vo_bo_fecha }}</a>
    </div>
    <div
      class="small-box m-0"
      v-if="$store.getters.can('cs.revisionServiciosInternos.voBoDelete')"
    >
      <button class="btn btn-danger w-100" @click="destroy()">Quitar VoBo</button>
    </div>
  </div>
  <div
    v-else-if="
      $store.getters.can('cs.revisionServiciosInternos.voBoTarifasAdicionales')
    "
    class="col-md-4"
  >
    <div class="card card-info m-0">
      <div class="card-header">
        <h3 class="card-title">
          <i class="fas fa-check"></i>
          Visto Bueno
        </h3>
      </div>
      <div class="card-body" v-if="$parent.servicioInterno.tarifas">
        <div class="row">
          <div class="col-sm-12 form-group clearfix">
            <div class="icheck-primary d-inline">
              <input
                type="checkbox"
                id="checkboxVtoBno"
                v-model="form.cs_det_tarifa"
                :class="
                  $v.form.cs_det_tarifa.$invalid ? 'is-invalid' : 'is-valid'
                "
                @click="resetObservcion"
                :disabled="
                  $parent.servicioInterno.tarifas.length == 0 ||
                    $parent.$refs.CsRevisionPresupuesto.totalPresupuesto !== 100
                "
              />
              <label for="checkboxVtoBno">Aprobar</label>
            </div>
            <br />
            <span
              class="badge badge-warning"
              v-if="$parent.servicioInterno.tarifas.length == 0"
            >
              No hay tarifas de trabajo asignadas
            </span>
          </div>
          <div class="col-sm-12">
            <label>Observación:</label>
            <textarea
              class="form-control form-control-sm"
              v-model="form.observacion"
              :class="$v.form.observacion.$invalid ? 'is-invalid' : 'is-valid'"
              :disabled="!form.cs_det_tarifa"
            ></textarea>
          </div>
        </div>
      </div>
      <div
        class="card-footer"
        v-if="
          !$v.form.$invalid &&
            $parent.$refs.CsRevisionPresupuesto.totalPresupuesto == 100
        "
      >
        <div class="row justify-content-between">
          <button
            type="button"
            data-toggle="tooltip"
            class="btn btn-sm bg-primary"
            @click="save"
          >
            <i class="fas fa-save mr-1"></i>
            <span>Aprobar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CsRevisionTarifasVtoBno",

  data() {
    return {
      vto_bno: null,
      form: {},
    };
  },

  validations: {
    form: {
      cs_det_tarifa: {
        required,
      },
      observacion: {
        required,
      },
    },
  },

  methods: {
    getIndex(servicio) {
      this.vto_bno = servicio;
      this.form = {
        id: this.vto_bno.id,
        cs_det_tarifa: false,
        vo_bo_user_id: this.$store.state.user.id,
        observacion: null,
      };
    },

    resetObservcion() {
      this.form.observacion = null;
    },

    save() {
      this.form.vo_bo_fecha = moment().format("YYYY-MM-DD HH:mm");
      this.form.estado = 5; // Se pasa a estado Visto Bueno

      axios
        .put("/api/cs/servicios", this.form)
        .then(() => {
          this.$parent.$refs.closeModalDetalles.click();
          this.$parent.$parent.getIndex(this.$parent.$parent.page);
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.$parent.$parent.cargando = false;
        });
    },
    destroy() {
      this.$swal({
        title: "Esta seguro de eliminar el visto bueno?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/cs/revision_servicios_internos/vobo/" + this.form.id)
            .then((response) => {
              this.$parent.$refs.closeModalDetalles.click();
              this.$parent.$parent.getIndex(this.$parent.$parent.page);
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
};
</script>
