var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.vto_bno && _vm.vto_bno.vo_bo_user_id)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"small-box bg-teal m-0"},[_c('div',{staticClass:"inner"},[_c('h3',[_vm._v("Visto Bueno")]),_c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v(_vm._s(_vm.vto_bno.vo_bo_user.name))])]),_c('p',{staticClass:"text-sm mb-1"},[_vm._v(_vm._s(_vm.vto_bno.observacion))])]),_vm._m(0),_c('a',{staticClass:"small-box-footer"},[_vm._v(_vm._s(_vm.vto_bno.vo_bo_fecha))])]),(_vm.$store.getters.can('cs.revisionServiciosInternos.voBoDelete'))?_c('div',{staticClass:"small-box m-0"},[_c('button',{staticClass:"btn btn-danger w-100",on:{"click":function($event){return _vm.destroy()}}},[_vm._v("Quitar VoBo")])]):_vm._e()]):(
    _vm.$store.getters.can('cs.revisionServiciosInternos.voBoTarifasAdicionales')
  )?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card card-info m-0"},[_vm._m(1),(_vm.$parent.servicioInterno.tarifas)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 form-group clearfix"},[_c('div',{staticClass:"icheck-primary d-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.cs_det_tarifa),expression:"form.cs_det_tarifa"}],class:_vm.$v.form.cs_det_tarifa.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"checkbox","id":"checkboxVtoBno","disabled":_vm.$parent.servicioInterno.tarifas.length == 0 ||
                  _vm.$parent.$refs.CsRevisionPresupuesto.totalPresupuesto !== 100},domProps:{"checked":Array.isArray(_vm.form.cs_det_tarifa)?_vm._i(_vm.form.cs_det_tarifa,null)>-1:(_vm.form.cs_det_tarifa)},on:{"click":_vm.resetObservcion,"change":function($event){var $$a=_vm.form.cs_det_tarifa,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "cs_det_tarifa", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "cs_det_tarifa", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "cs_det_tarifa", $$c)}}}}),_c('label',{attrs:{"for":"checkboxVtoBno"}},[_vm._v("Aprobar")])]),_c('br'),(_vm.$parent.servicioInterno.tarifas.length == 0)?_c('span',{staticClass:"badge badge-warning"},[_vm._v(" No hay tarifas de trabajo asignadas ")]):_vm._e()]),_c('div',{staticClass:"col-sm-12"},[_c('label',[_vm._v("Observación:")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.observacion),expression:"form.observacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.observacion.$invalid ? 'is-invalid' : 'is-valid',attrs:{"disabled":!_vm.form.cs_det_tarifa},domProps:{"value":(_vm.form.observacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "observacion", $event.target.value)}}})])])]):_vm._e(),(
        !_vm.$v.form.$invalid &&
          _vm.$parent.$refs.CsRevisionPresupuesto.totalPresupuesto == 100
      )?_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row justify-content-between"},[_c('button',{staticClass:"btn btn-sm bg-primary",attrs:{"type":"button","data-toggle":"tooltip"},on:{"click":_vm.save}},[_c('i',{staticClass:"fas fa-save mr-1"}),_c('span',[_vm._v("Aprobar")])])])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-check"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Visto Bueno ")])])
}]

export { render, staticRenderFns }